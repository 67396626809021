import React, { Component } from "react";
import "./App.css";
import {
  withRouter
} from "react-router-dom";

//  Magnific-popup
import "./assets/css/magnific-popup.css";

//  css
import "./assets/css/bootstrap.min.css";
import "./assets/css/icons.css";
import "./assets/css/style.css";
import Section from "./section";
import NavbarPage from "./components/Navbar/Navbar_Page";

class App extends Component {

	constructor(props) {
		super(props);
		this.state = {
			pos: document.documentElement.scrollTop,
			imglight: false,
			navClass: "",
			isStickyNav: true,
		};
	}

	componentDidMount() {
		window.addEventListener("scroll", this.scrollNavigation, true);
		this.scrollNavigation();
	}

	componentWillUnmount() {
		window.removeEventListener("scroll", this.scrollNavigation, true);
	}

	scrollNavigation = () => {
		var scrollup = document.documentElement.scrollTop;

		if (scrollup > this.state.pos) {
			this.setState({navClass: "darkheader", imglight: false});
			this.setState({isStickyNav: false});
		} else if (window.innerWidth <= 768) {
			this.setState({navClass: "darkheader", imglight: false});
			this.setState({isStickyNav: false});
		} else {
			this.setState({navClass: "", imglight: true});
			this.setState({isStickyNav: true});
		}
	};

	render() {
		return (
			<React.Fragment>
				<NavbarPage
					navclass={this.state.navClass}
					imglight={this.state.imglight}
					isStickyNav={this.state.isStickyNav}
				/>

				<Section/>

			</React.Fragment>
		);
	}
}

export default withRouter(App);
