import React, {Component} from "react";
import {
	Container,
	Row,
	Col,
	Navbar,
	Collapse,
	Nav,
	NavItem,
	NavLink,
	NavbarToggler,
	NavbarBrand,
} from "reactstrap";

//Import Stickey Header
import StickyHeader from "react-sticky-header";
import NavDropdown from 'react-bootstrap/NavDropdown'

import logolight from "../../assets/images/logo-light.png";
import logodark from "../../assets/images/logo-dark.png";

class NavbarPage extends Component {
	prevScrollpos = 0;

	constructor(props) {
		super(props);
		this.state = {
			isOpen: false,
			topPos: "0",
			isOpenMenu: false,
			navCenterClass: "",
		};
		this.toggleLine = this.toggleLine.bind(this);

		this.handleScrollMenu = this.handleScrollMenu.bind(this);
	}

	handleScrollMenu = async () => {
		let currentScrollPos = window.pageYOffset;
		// if (this.prevScrollpos > currentScrollPos) {
		// 	await this.setState({topPos: "0"});
		// } else {
		// 	await this.setState({topPos: "-420px"});
		// }
		this.prevScrollpos = currentScrollPos;

		if (window.innerWidth <= 768) {
			await this.setState({navCenterClass: ""});
		} else {
			await this.setState({
				navCenterClass: "navbar-nav mx-auto navbar-center",
			});
		}
	};

	componentDidMount() {
		this.prevScrollpos = window.pageYOffset;
		window.addEventListener("scroll", this.handleScrollMenu);
		if (window.innerWidth <= 768) {
			this.setState({navCenterClass: ""});
		} else {
			this.setState({navCenterClass: "navbar-nav mx-auto navbar-center"});
		}
	}

	toggleLine() {
		this.setState((prevState) => ({isOpen: !prevState.isOpen}));
	}

	toggle = async () => {
		await this.setState({isOpenMenu: !this.state.isOpenMenu});
	};

	render() {
		//Store all Navigationbar Id into TargetID variable(Used for Scrollspy)
		return (
			<React.Fragment>
				<StickyHeader
					header={
						<Navbar
							className={`navbar navbar-expand-xl fixed-top navbar-custom ${
								this.props.isStickyNav === true
									? `navbar-dark`
									: `navbar-dark`
								}`}
							id="navbar"
							style={{top: this.state.topPos}}
						>
							<Container>
								<NavbarBrand href="/" className="logo">
									<img
										src={this.props.imglight ? logolight : logodark}
										alt=""
										className="logo-light"
										height={64}
									/>
								</NavbarBrand>

								<NavbarToggler
									className="navbar-toggler"
									type="button"
									aria-label="Toggle navigation"
									onClick={this.toggle}
								>
									<i className="remixicon-menu-fill"/>
								</NavbarToggler>
								<Collapse
									id="navbarCollapse"
									isOpen={this.state.isOpenMenu}
									navbar
								>
									<Nav className="navbar-nav navbar-center">
										<NavItem className={"nav-item"}>
											<NavLink href={"https://www.healthics.com"} className={"nav-link"}>
												Personal
											</NavLink>
										</NavItem>
										<NavItem className={"nav-item"}>
											<NavDropdown title="BUSINESS" id="nav-dropdown">
												<NavDropdown.Item href={"https://cloud.healthics.com"} className={"text-black"} stye={{color:"#000000"}}>
													Healthics Cloud
												</NavDropdown.Item>
												<NavDropdown.Item  href={"https://api.healthics.com"} className={"text-black"} stye={{color:"#000000"}}>
													Healthics API
												</NavDropdown.Item>
											</NavDropdown>
										</NavItem>
										<NavItem className={"nav-item active"}>
											<NavLink href={"https://gov.healthics.com"} className={"nav-link"}>
												Government
											</NavLink>
										</NavItem>
									</Nav>
								</Collapse>
							</Container>
						</Navbar>
					}
					stickyOffset={-100}
				/>
			</React.Fragment>
		);
	}
}

export default NavbarPage;
